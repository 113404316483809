<template>
  <v-col
    class="col-12 main-content pa-0"
  >
    <summary-card-bar v-if="showSummaries" />

    <v-row
      v-if="showMessage"
      class="ma-0"
    >
      <error-unknown :status-message="currentStatusMessage" />
    </v-row>

    <v-row
      v-else-if="isLoading"
      class="ma-0 fill-height"
    >
      <message-view />
    </v-row>

    <keep-alive>
      <component
        :is="currentComponent"
        v-if="showContent"
      />
    </keep-alive>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import paths from 'src/router/paths'
import render from 'src/utils/render'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapActions: mapMessagesActions, mapGetters: mapMessagesGetters } = createNamespacedHelpers('messages')
const { mapActions: mapToolsActions, mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

// noinspection ES6MissingAwait
export default {
  name: 'MainContent',
  components: {
    Backlogs: () => import('src/docs/views/Backlogs'),
    Creatives: () => import('src/admanager/views/Creatives'),
    CustomReports: () => import('src/custom-reports/views/CustomReports'),
    DataRefresh: () => import('src/docs/views/DataRefresh'),
    Deprecation: () => import('src/docs/views/Deprecation'),
    Docs: () => import('src/docs/views/Docs'),
    Discrepancy: () => import('src/delivery/views/Discrepancy'),
    ErrorUnknown: () => import('src/messages/components/ErrorUnknown'),
    Health: () => import('src/programmatic/views/Health'),
    MessageView: () => import('src/messages/components/MessageView'),
    Lineitems: () => import('src/admanager/views/LineItems'),
    Networks: () => import('src/admanager/views/Networks'),
    Organization: () => import('src/internal/views/Organization'),
    Orders: () => import('src/admanager/views/Orders'),
    Overview: () => import('src/internal/views/Overview'),
    Pacing: () => import('src/delivery/views/Pacing'),
    Person: () => import('src/internal/views/Person'),
    PrivacyPolicy: () => import('src/docs/views/PrivacyPolicy'),
    Project: () => import('src/internal/views/Project'),
    Releases: () => import('src/docs/views/Releases'),
    SummaryCardBar: () => import('src/tools/components/summary/SummaryCardBar'),
    TimePage: () => import('src/internal/views/Time'),
    Yield: () => import('src/programmatic/views/Yield'),
    Whitelisting: () => import('src/docs/views/Whitelisting'),
  },
  data() {
    return {
      currentReportParams: null,
      moduleMap: {
        'custom-reports': 'customReports',
      },

      isHydrating: false,
      pendingHydration: false,

      reportNameMap: {
        'campaign-tracking': 'custom-reports',
        'campaign-tracking-1': 'custom-reports',
        'campaign-tracking-2': 'custom-reports',
        'monthly-progress': 'custom-reports',
        'progress-report': 'custom-reports',
        revenue: 'custom-reports',
        'revenue-at-risk': 'custom-reports',
        'schneps-mid-month': 'custom-reports',
        time: 'time-page',
      },
    }
  },
  computed: {
    ...mapAuthGetters(['inDemoMode', 'isInternalUser', 'network']),
    ...mapMessagesGetters(['currentMessage', 'statusCodes']),
    ...mapToolsGetters([
      'columnsByKey',
      'currentColumns',
      'currentItems',
      'currentViewId',
      'isCustomReportRoute',
      'isDocsRoute',
      'isReportIdRequiredOnRoute',
      'reportKey',
      'routeName',
      'selectedSearch',
      'showCurrentSavedFilters',
    ]),
    ...mapToolsState({
      adjuster: (state) => state.adjuster,
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
      reportPath: (state) => state.reportPath,
      subId: (state) => state.subId,
    }),
    isCustomReportRouteName() {
      return this.isCustomReportRoute || this.$route.name === 'custom-reports'
    },
    currentComponent() {
      // We rename Time to avoid naming collisions in the browser.
      return this.reportNameMap[this.reportName] || (this.isCustomReportRouteName && 'custom-reports') || this.reportName
    },
    storeName() {
      const { moduleMap, routeName = this.reportName } = this
      const currentRouteName = routeName || this.$route.name
      return moduleMap[currentRouteName] || currentRouteName
    },
    isStoreRegistered() {
      return this.$store.hasModule(this.storeName)
    },
    currentParams() {
      const { adjuster, reportId, reportKey, reportName, reportPath, routeName, subId } = this

      return {
        adjuster,
        dateRange: null,
        reportId,
        reportKey,
        reportName,
        reportPath,
        routeName,
        subId,
      }
    },
    currentReportId() {
      return this.currentParams?.reportId
    },
    currentSubId() {
      return this.currentParams?.subId || '*'
    },
    columns() {
      return this.isStoreRegistered && this.$store.state[this.storeName]?.columns
    },
    isLoading() {
      const {
        currentColumns,
        currentMessage,
        currentItems,
        currentViewId,
        isCustomReportRouteName,
        isDocsRoute,
        isStoreRegistered,
      } = this

      const requiresLoading = () => !isCustomReportRouteName && !isDocsRoute
      const isPendingStore = () => !isStoreRegistered && 'store'
      const isPendingSetup = () => (!currentViewId || !currentColumns || !currentMessage) && 'setup'
      const isLoadingData = () => currentItems === undefined && 'data'
      const isMessaging = () => currentMessage?.isActive && 'message'

      return requiresLoading() && (isPendingStore() || isPendingSetup() || isLoadingData() || isMessaging())
    },
    showProfilePage() {
      const internalReports = ['organization', 'person', 'project']
      return internalReports.indexOf(this.$route.params.reportName) !== -1 && this.currentReportId
    },
    showContent() {
      const { currentMessage, currentReportId, isCustomReportRouteName, isDocsRoute, isReportIdRequiredOnRoute } = this

      if (isCustomReportRouteName || isDocsRoute) return true

      return (!isReportIdRequiredOnRoute || currentReportId) && currentMessage?.isActive === false
    },
    showSummaries() {
      const { showCurrentSavedFilters, showMessage, showProfilePage } = this
      return showCurrentSavedFilters && !showMessage && !showProfilePage
    },
    hasNoReport() {
      const {
        isReportIdRequiredOnRoute,
        reportKey,
        selectedSearch,
      } = this

      const map = {
        [paths.ADMANAGER.CREATIVES]: 'networkCode',
        [paths.ADMANAGER.LINEITEMS]: 'networkCode',
        [paths.ADMANAGER.NETWORKS]: 'networkCode',
        [paths.ADMANAGER.ORDERS]: 'networkCode',
        [paths.DELIVERY.PACING]: 'hasPacing',
        [paths.INTERNAL.PERSON]: 'isPersonUserInternal',
        [paths.DELIVERY.DISCREPANCY]: 'hasDiscrepancy',
        [paths.PROGRAMMATIC.HEALTH.ADX]: 'hasHealthAdx',
        [paths.PROGRAMMATIC.HEALTH.NETWORK]: 'hasHealthNetwork',
        [paths.PROGRAMMATIC.YIELD.PARTNER]: 'hasYieldPartner',
      }

      return isReportIdRequiredOnRoute && (!selectedSearch || !selectedSearch[map[reportKey]])
    },
    showMessage() {
      return this.hasNoReport || (!this.showContent && !this.isLoading)
    },
    currentStatusMessage() {
      if (this.isReportIdRequiredOnRoute && !this.reportId) return 'Choose a Publisher'
      return this.hasNoReport ? `No Report Available for ${this.selectedSearch?.text || 'Selected'}` : 'Error Unknown'
    },
    currentErrorCode() {
      const { currentReportId, selectedSearch } = this
      const errorRequest = () => currentReportId && selectedSearch instanceof Error && 400
      const errorNotFound = () => currentReportId && !selectedSearch && 404
      return errorRequest() || errorNotFound()
    },
  },

  watch: {
    inDemoMode() {
      this.setup()
    },
    routeName(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.preconfigure(newValue)
      }
    },
    selectedSearch: {
      deep: true,
      handler(newValue, oldValue) {
        const requireHydration = () => {
          const isPendingHydration = () => this.pendingHydration && !this.isHydrating
          const hasChangedSelection = () => !this.pendingHydration && this.hasChanged(newValue, oldValue)

          return isPendingHydration() || hasChangedSelection()
        }

        if ((this.isReportIdRequiredOnRoute || this.reportId) && requireHydration()) {
          this.isHydrating = true
          this.pendingHydration = true
          this.setup().then(() => {
            this.pendingHydration = false
            this.isHydrating = false
          })
        }
      },
    },
    $route(newValue, oldValue) {
      if (!this.isReportIdRequiredOnRoute && this.hasChanged(newValue?.params, oldValue?.params)) {
        this.setup()
      }
    },
  },
  created() {
    if (this.isReportIdRequiredOnRoute) {
      this.pendingHydration = true
      this.setupView() // The watch on selectedSearch will handle the render.
    } else {
      this.setup()
    }
  },
  methods: {
    ...mapMessagesActions(['showLoadingMessage', 'showStatusMessage']),
    ...mapToolsActions(['getParams', 'isInvalidRequest']),

    async setup() {
      this.showLoadingMessage({ reportKey: this.reportKey, text: 'Loading ...' })

      await this.setupView()

      return this.renderView()
    },

    update(selectedSearch) {
      if (this.routeName) {
        render.ensureViewIsUpdatedAndRenderedImmediately(() => {
          const { columnsByKey, currentReportParams: params, routeName } = this
          const storeName = (routeName === 'custom-reports' && 'customReports') || routeName
          this.$store.dispatch(`${storeName}/getData`, { columnsByKey, params, selectedSearch }, { root: true })
        })
      } else {
        this.$store.dispatch('messages/showLoadingMessage', { reportKey: this.reportKey, text: 'Route Error' })
      }
    },

    async registerStore() {
      const storeMap = {
        admanager: () => import('../../admanager/store/store'),
        customReports: () => import('../../custom-reports/store/store'),
        delivery: () => import('../../delivery/store/store'),
        docs: () => import('../../docs/store/store'),
        internal: () => import('../../internal/store/store'),
        programmatic: () => import('../../programmatic/store/store'),
      }

      const { storeName } = this

      if (!storeName) return this.renderError(404)

      const { default: newStore } = await storeMap[storeName]()

      return !this.isStoreRegistered && this.$store.registerModule(storeName, newStore)
    },
    preconfigure(routeName) {
      const { isStoreRegistered } = this

      return !!routeName && !isStoreRegistered && this.registerStore(routeName)
    },
    setupTools(params, columns) {
      this.$store.commit('tools/isCurrentlyMerging', true)
      this.$store.commit('tools/isCurrentlyGrouping', true)

      return this.$store.dispatch('tools/setup', { columns, params }, { root: true })
    },
    async setParams() {
      const routeName = this.$route.name
      const { adjuster, network, reportId, reportKey, reportName, reportPath, subId } = this
      const seedParams = { adjuster, network, reportId, reportKey, reportName, reportPath, routeName, subId }

      return this.getParams(seedParams)
    },
    async setupView() {
      this.currentReportParams = await this.setParams()

      await this.preconfigure(this.routeName)

      const { columns, currentReportParams } = this

      return this.setupTools(currentReportParams, columns)
    },

    renderError(errorCode) {
      const { isInternalUser, reportKey } = this
      const statusCode = errorCode || (isInternalUser ? 404 : 308)

      return this.showStatusMessage({ reportKey, statusCode })
    },
    async renderSuccess() {
      const { reportKey, selectedSearch } = this
      const statusCode = 200

      this.update(selectedSearch)

      this.showStatusMessage({ reportKey, statusCode })

      this.$store.dispatch('tools/showFeatureModal')

      return selectedSearch
    },
    async renderView() {
      const { currentErrorCode } = this
      return currentErrorCode ? this.renderError(currentErrorCode) : this.renderSuccess()
    },
    hasChanged(newValue, oldValue) {
      const oldSearch = oldValue || {}
      const newSearch = newValue || {}

      return oldSearch.docId !== newSearch.docId
        || oldSearch.href !== newSearch.href
        || oldSearch.reportName !== newSearch.reportName
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.main-content {
  width: 100%;
  height: 100%;

  h1 {
    margin-bottom: 16px;
  }
}

.error-message,
.info-message {
  padding: 48px;
  background-color: $color-monochrome-white;
  border-radius: 6px;
}

.messenger > div {
  display: flex;
  align-items: center;

  .v-icon {
    margin-right: 6px;
  }
}
</style>
